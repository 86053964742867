export const extractQuestion = (poll, locale) => {
  if (locale.match(/^(en-GB|ar-SA|de-DE|es-ES|fr-FR|id-ID|it-IT|ko-KR|pt-BR|ja-JP)$/)) {
    return poll.question.translations.filter((question) => question.locale === locale)[0];
  } else {
    return poll.question.translations.filter((question) => question.locale === 'en-GB')[0];
  }
};

export const extractMessage = (message, locale) => {
  console.log(message);
  if (locale.match(/^(en-GB|ar-SA|de-DE|es-ES|fr-FR|id-ID|it-IT|ko-KR|pt-BR|ja-JP)$/)) {
    return message.translations.filter((msg) => msg.locale === locale)[0].value;
  } else {
    return message.translations.filter((msg) => msg.locale === 'en-GB')[0].value;
  }
};

export const extractPollChoices = (poll, locale, _type) => {
  let choices = [];
  var i;
  for (i = 0; i < poll.pollChoices.length; i++) {
    let choice;

    if (locale.match(/^(en-GB|ar-SA|de-DE|es-ES|fr-FR|id-ID|it-IT|ko-KR|pt-BR|ja-JP)$/)) {
      choice = poll.pollChoices[i].text.translations.filter((option) => option.locale === locale)[0];
    } else {
      choice = poll.pollChoices[i].text.translations.filter((option) => option.locale === 'en-GB')[0];
    }

    choices.push({ id: poll.pollChoices[i].id, option: choice, tagName: poll.pollChoices[i].tagName });
  }
  return choices;
};

export const isIOS = () => {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const getFont = (key) => {
  switch (key) {
    case 'Arial':
      return 'Arial';
    case 'Times':
      return 'Times New Roman';
    case 'Courier':
      return 'Courier New';
    default:
      return 'PoppinsRegular';
  }
};
