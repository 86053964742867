import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Predictor } from './predictor/Predictor';
import { Polling } from './polling/Polling';

function App() {
  return (
    <BrowserRouter basename={'/v2'}>
      <Routes>
        <Route path={'poll/:pollId'} element={<Polling />} />
        <Route path={'predictor'} element={<Predictor />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
