import React from 'react';
import axios from 'axios';
import { reducer } from './reducer';

const StateContext = React.createContext({});
const StateDispatchContext = React.createContext(() => null);
const initialState = {
  showVoteCompletedModal: false,
  poll: null,
  question: null,
  pollChoices: [],
  customisation: {},
  voted: false,
  type: '',
  choiceId: '',
  choiceText: '',
  choiceImageURL: null
};

export const useStateContext = () => {
  const context = React.useContext(StateContext);
  if (typeof context === undefined) {
    throw new Error('Cannot use `useStateContext` outside a StateProvider');
  }
  return context;
};

export const useStateDispatchContext = () => {
  const context = React.useContext(StateDispatchContext);
  if (typeof context === undefined) {
    throw new Error('Cannot use `useStateDispatchContext` outside a StateProvider');
  }
  return context;
};

export const StateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const api_url = process.env.REACT_APP_POLLING_API_URL;
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  let locale = params.locale || 'en-GB';
  const pollId = window.location.href.split('/').pop().split('?')[0];
  let type = params.type || 'Lineups';
  let matchCentreUrl = params.matchCentreUrl || '';
  //let tenantId = "";

  const fetchPoll = async () => {
    axios.get(`${api_url}/poll/${pollId}`).then((res) => {
      console.log(res.data.response.poll.tenantId);
      dispatch({
        type: 'SET_POLL',
        data: res.data.response.poll,
        locale: locale,
        pollType: type,
        matchCentreUrl: matchCentreUrl
      });
    });
  };

  const submitVote = (tenantId,answer) => {
    return axios.post(`${api_url}/submit-vote`, { tenantId,pollId, id: answer.id }).then((res) => {
      dispatch({
        type: 'SET_VOTE',
        value: true,
        id: answer.id,
        image: answer.imageURL
      });
    });
  };

  const dispatchState = { fetchPoll, submitVote, dispatch };

  return (
    <StateContext.Provider value={state}>
      <StateDispatchContext.Provider value={dispatchState}>{children}</StateDispatchContext.Provider>
    </StateContext.Provider>
  );
};
